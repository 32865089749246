import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';


function FairBillingPolicy() {
  return (
    <div className="container">
        <div className="fnt-16">
            <h1 className="my-5 py-5 text-center fw-bold">Fair Billing Policy</h1>
            <h1 className="fw-400 my-5">DripFunnel offers a fair billing policy where you are only billed for the licences you purchase.</h1>
            <div className="fw-bold mb-4">The short version</div>
            <div className="mb-4">
                <ul>
                    <li className="mb-4">Each user must have their own licence. You must not share licences.</li>
                    <li className="mb-4">Each month, you'll be billed based on the licences you have.</li>
                    <li className="mb-4">You can add or remove licences whenever you like.</li>
                    <li className="mb-4">You can assign and re-assign team members to licences however you like.</li>
                    <li className="mb-4">At any time, you can view your current license level from your Billing page.</li>
                    <li className="mb-4">Fair pro-rata billing calculations will be made automatically when you update your licences, and you will be billed immediately to bring your account up to date for the next billing period.</li>    
                </ul>
            </div>
            <div className="fw-bold mb-4">Adding team members</div>
            <div className="mb-4">If you'd like to invite a colleague to your account, you can create a new licence for them and invite them to join. </div>
            <div className="mb-4">If you're paying annually, we'll bill you for the amount of time left on your billing period. For example, if you're paying annually and add a licence halfway through the year, we'll bill you for the 6 months remaining.</div>
            <div className="fw-bold mb-4">Credit card charges</div>
            <div className="mb-4">You’ll see the first credit card charge from DripFunnel on the day you purchase an DripFunnel plan. Here are some other times you’ll see charges:</div>
            <div className="mb-4">On the monthly renewal date if your account is on a monthly payment schedule.</div>
            <div className="mb-4">On the annual renewal date if your account is on an annual payment schedule.</div>
            <div className="mb-4">Immediately, if you update your licences, change your plan, or your change your billing period, and we need to bring your account up to date.</div>
            <div className="fw-bold mb-4">Paying Annually</div>
            <div className="mb-4">If you're paying annually, we'll invoice you for the full year for a set number of licences.</div>
            <div className="mb-4">If you add more licences to your account, we'll invoice you immediately to bring your account up to date.</div>
            <div className="fw-bold mb-4">Deactivating team members</div>
            <div className="mb-4">Alfres makes it easy to manage the team members who have access to your database. Once you have deactivated a team member, you will then need to remove their licence if you no longer wish to pay for it. When you remove a licence, we'll add a pro-rata credit to your DripFunnel account, and it'll be applied when new team members are invited, or on your next renewal date.</div>
            <div className="mb-4">This credit has no currency or exchange value, is non-transferable and non-refundable and will expire if you choose to cancel your Beacon subscription.</div>
            <div className="fw-bold mb-4">‍Cancelation</div>
            <div className="mb-5">‍You can cancel your paid DripFunnel account at any time. An account canceled at any point in the billing cycle will be charged for all active users in that billing cycle.</div>
        </div>
    </div>
  );
}

export default FairBillingPolicy;
